/* Roboto - regular */
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts//Roboto/roboto-regular-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts//Roboto/roboto-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts//Roboto/roboto-regular-webfont.woff') format('woff'); /* Modern Browsers */
}

/* Basier Circle - medium */
@font-face {
  font-family: 'Basier Circle';
  src: url('./assets/fonts/BasierCircle/basiercircle-medium-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/BasierCircle/basiercircle-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/BasierCircle/basiercircle-medium-webfont.woff') format('woff'); /* Modern Browsers */
}

/* Basier Circle - semibold */
@font-face {
  font-family: 'Basier Circle';
  src: url('./assets/fonts/BasierCircle/basiercircle-semibold-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/BasierCircle/basiercircle-semibold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/BasierCircle/basiercircle-semibold-webfont.woff') format('woff'); /* Modern Browsers */
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
