.footer {
  margin-top: 40px;
  width: 100%;
  background: #011332;
  color: #FFFFFF;
}

.footer__top {
  padding: 70px 30px;
}

.footer__top__left {
  padding: 0px 0px 30px;
}

.footer__top__left__logoContainer {
  width: auto;
  height: auto;
  margin-bottom: 20px;
}

.footer__top__left__logoContainer > img {
  width: 124px;
  height: auto;
  cursor: pointer;
}

.footer__top__left__companyDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #CCCCCC;
}

.footer__bar__vertical {
  background-color: #011840;
  height: 2px;
  width: 100%;
}

.footer__top__right {
  padding: 30px 0px 0px;
}

.footer__top__right__navMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.footer__top__right__navMenu__navItems {
  list-style: none;
}

.footer__top__right__navMenu__navItems > li {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.footer__top__right__socialIcons__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #E6E6E6;
  margin-bottom: 20px;
}

.footer__top__right__socialIcons__icons {
  width: 55px;
  display: flex;
  justify-content: space-between;
}

.footer__bar__horizontal {
  background-color: #011840;
  width: 100%;
  height: 2px;
}

.footer__bottom__text {
  padding: 50px 0px;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #E6E6E6;
  opacity: 0.6;
}


/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .footer__top {
    padding: 50px 60px 15px;
  }

  .footer__top__left__companyDescription {
    margin-bottom: 2rem;
  }

  .footer__top__right {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  .footer__top__right__navMenu {
    margin-bottom: 0px;
    flex-grow: 2;
  }

  .footer__top__right__navMenu__navItems {
    margin-bottom: 0px;
  }

  .footer__top__right__socialIcons {
    flex-grow: 2;
    display: flex;justify-content: flex-end;
  }

  .footer__bottom {
    padding: 0px 60px;
  }

  .footer__bottom__text {
    padding: 30px 0px;
    font-size: 13px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .footer__top {
    padding: 70px 100px 45px;
    display: flex;
  }

  .footer__top__left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-bottom: 0px;
  }

  .footer__top__left__logoContainer {
    margin-bottom: 0px;
    margin-right: 100px;
    width: 124px;
  }

  .footer__top__left__logoContainer__img {
    width: 100%;
    height: 100%;
  }

  .footer__top__left__companyDescription {
    margin-bottom: 0px;
    max-width: 280px;
    line-height: 24px;
  }

  .footer__bar__vertical {
    display: none;
  }

  .footer__top__right {
    flex-grow: 1;
    padding-top: 0px;
  }

  .footer__top__right__navMenu {
    margin-bottom: 0px;
    flex-grow: 1;
  }

  .footer__top__right__navMenu__navItems > li {
    margin-bottom: 34px;
  }

  .footer__bottom__text {
    padding: 30px 0px;
    font-size: 14px;
  }
}
