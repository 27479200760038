.ph__heroSection {
  width: 100%;
  height: 550px;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.ph__heroSection > img {
  position: absolute;
  transform: translateX(-30%) translateY(-20%);
  z-index: -1;
}

.ph__heroSection__content {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px;
  text-align: center;
}

.ph__heroSection__content > h3 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: auto;
  margin-bottom: 10px;
}

.ph__heroSection__content > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
  color: #E6E6E6;
  margin-bottom: 0px;
}

.projectsList {
  padding: 100px 30px;
  position: relative;
}

.projectList__card {
  height: auto;
  margin-bottom: 30px;
}

.projectList__card > img {
  width: 100%;
}

.projectList__card__content {
  padding: 20px 15px 0px;
  max-width: 360px;
}

.projectList__card__content__date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #585860;
}

.projectList__card__content__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.projectList__card__content__desc {
    font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #262424;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .ph__heroSection {
    height: 396px;
  }

  .ph__heroSection > img {
    transform: none;
    width: auto;
    height: 100%;
  }

  .ph__heroSection__content > h3 {
    font-weight: 500;
    font-size: 36px;
  }
  
  .ph__heroSection__content > p {
    line-height: 27px;
    font-size: 18px;
  }

  .projectsList {
    padding: 60px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .ph__heroSection {
    height: 540px;
  }

  .ph__heroSection > img {
    width: 100%;
    height: auto;
  }

  .ph__heroSection__content > h3 {
    font-size: 45px;
    line-height: 56px;
    text-transform: capitalize;
  }

  .ph__heroSection__content > p {
    line-height: 31px;
    font-size: 22px;
  }

  .projectsList {
    padding: 100px 70px;
  }
}
