@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap);
/* global css */
.mainContainer {
  position: relative;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.hm__heroSection {
  width: 100%;
  height: 650px;
  overflow: hidden;
  position: relative;
}

.hm__heroSection__image__overlay {
  width: inherit;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: linear-gradient(90deg, #011332 -7.01%, rgba(0, 0, 0, 0) 108.97%);
}

.hm__heroSection__image {
  width: inherit;
  height: 100%;
  position: absolute;
  z-index: -5;
}

.hm__heroSection__image > img {
  height: 140%;
  -webkit-transform: translateX(-28%);
          transform: translateX(-28%);
}

.hm__heroSection__content__container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.hm__heroSection__content__container__items {
  width: 100%;
  padding: 0px 30px;
}

.hm__heroSection__content__container__items > p {
  font-family: Basier Circle;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #FFFFFF;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.section1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: hidden; */
}

.section1__box {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.section1__box > img {
  max-width: 100%;
  height: auto;
  position: relative;
}

.section1__box2 {
  /* padding: 120px 30px; */
  height: 440px;
  background-color: #CE5243;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section1__box2__content {
  padding: 0px 30px;
  overflow-x: hidden;
}

/* change to weight to italic (400) */
.section1__box2__description {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #E6E6E6;
  padding-bottom: 10px;
}

.section1__box2__autor {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #011332;
}

.section1__box2__img1 {
  position: absolute;
  top: 0;
  right: 0;
}

.section1__box2__img2 {
  position: absolute;
  bottom: 0px;
  left: -40px;
}

.section3 {
  width: 100%;
  height: auto;
  background: #00325E;
  padding: 80px 30px;
}

.section3__mainText {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  letter-spacing: 0.6px;
  color: #FFFFFF;
}

.section5 {
  width: 100%;
  height: auto;
  position: relative;
}

.section5__image1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -20;
}

.section5__image2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -24;
}

.section5__heading {
  padding: 80px 30px;
  position: relative;
}

.section5__heading__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #262424;
  margin-bottom: 20px;
}

.section5__heading__subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #585860;
}

.section5__content > img {
  width: 100%;
}

.section5__content__top,
.section5__content__bottom {
  display: flex;
}

.section5__content__box {
  width: 50%;
  display: flex;
}

.section5__content__box__content {
  padding: 35px 30px;
}

.section5__content__box__content__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 56px;
  color: #469EEB;
}

.section5__content__box__content__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #585860;
}

.section6__heading {
  padding: 80px 30px;
}

.section6__heading__top {
  margin-bottom: 80px;
}

.section6__heading__top__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #262424;
  margin-bottom: 20px;
}

.section6__heading__top__subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #262424;
}

.section6__heading__bottom__link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.section6__heading__bottom__link > a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #469EEB;
  text-decoration: none;
  margin-right: 10px;
}

.section6__content {
  display: grid;
  grid-template-columns: auto;
}

.section6__content__item {
  padding: 60px 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: #00325E;
  color: #FFFFFF;
}

.section6__content__item > img {
  width: auto;
  height: 138px;
}

.section6__content__item__text {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #FFFFFF;
  margin-top: 20px;
}

.section6__content__item:nth-child(2) {
  background: #1B6780;
}

.section6__content__item:nth-child(3) {
  background: #469EEB;
}

.section6__content__item:nth-child(4) {
  background: #F9CFB6;
}

.section6__content__item:nth-child(5) {
  background: #00325E;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .hm__heroSection__content__container__items {
    padding: 0px 60px;
  }

  .hm__heroSection__content__container__items > p {
    max-width: 630px;
  }

  .section1 {
    flex-direction: row;
  }

  .section1__box2 {
    height: auto;
  }

  .section1__box2__img2 {
    max-width: 150px;
    max-height: 150px;
    left: 3px;
  }

  .section1__box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .section3 {
    padding: 80px 60px;
  }

  .section5__heading {
    padding: 80px 60px;
  }

  .section5__content__box__content {
    padding: 35px 60px;
  }

  .section6__heading {
    padding: 80px 60px;
  }

  .section6__content__item {
    padding: 60px 60px;
  }

  .section6__content {
    grid-template-columns: 50% 50%;
  }

  .section6__content__item > img {
    height: 100px;
  }

  .section6__content__item__text {
    font-size: 20px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .hm__heroSection {
    height: 750px;
  }

  .hm__heroSection__image > img {
    -webkit-transform: none;
            transform: none;
    width: 100%;
    height: 100%;
  }

  .hm__heroSection__content__container__items {
    padding: 0px 100px;
  }

  .hm__heroSection__content__container__items > p {
    max-width: 700px;
    margin-bottom: 0px;
    font-size: 45px;
    line-height: 56px;
  }

  .section1__box {
    width: 100%;
    height: auto;
  }

  .section1__box > img {
    width: 100%;
    height: auto;
  }

  .section1__box {
    width: 35%;
  }

  .section1__box2 {
    width: 30%;
  }

  .section1__box2__description {
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 8px;
  }
  
  .section1__box2__autor {
    font-size: 17px;
    font-weight: 400;
  }

  .section3 {
    padding: 150px 100px;
  }
  
  .section3__mainText {
    font-size: 38px;
    padding: 0px;
    font-weight: 500;
    line-height: 49px;
  }

  .section5__heading {
    padding-left: 100px;
    padding-right: 100px;
  }

  .section5__heading__title {
    margin-bottom: 15px;
    max-width: 670px;
    text-transform: capitalize;
  }

  .section5__content > img {
    padding-left: 100px;
    padding-right: 80px;
  }

  .make-element-horiontal {
    width: 100%;
    display: flex;
    padding-left: 100px;
  }

  .section5__content__top, .section5__content__bottom {
    width: 100%;
    display: flex;
  }

  .section5__content__box {
    width: 50%;
    display: block;
  }

  .section5__content__box__content {
    padding: 100px 0px;
  }

  .section5__content__box__content__title {
    font-size: 70px;
    font-weight: 500;
    line-height: 88px;
    margin-top: 0px;
  }

  .section5__content__box__content__description {
    font-size: 17px;
    margin-bottom: 0px;
    max-width: 350px;
  }

  .section6__heading {
    padding-left: 100px;
    padding-right: 100px;
  }

  .section6__heading__top__title {
    margin-bottom: 15px;
    max-width: 670px;
    text-transform: capitalize;
  }

  .section6__heading__top__subtitle {
    color: #585860;
  }

  .section6__content {
    padding-left: 100px;
    padding-right: 100px;
  }

  .section6__content__item__text {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 0px;
  }

  .section6__content__item {
    padding: 90px 60px;
  }

  .section6__content__item > img {
    height: 80px;
  }
}

.header {
  width: 100%;
  height: 80px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  position: absolute;
  z-index: 10;
}

.header__logoContainer {
  width: auto;
  height: auto;
}

.header__logoContainer__logo {
  width: 60%;
  height: auto;
  cursor: pointer;
}

.header__navMenu .header__navItems {
  display: none;
  cursor: pointer;
}

.header__navMenu > button {
  border: none;
  background: none;
}

.header__navMenu__icon {
  cursor: pointer;
}

.header__menuContainer {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #00325E;
  top: 0px;
  left: 0px;
}

.header__menuContainer__closebutton {
  width: 100%;
  height: auto;
  padding: 30px;
  display: flex;
  justify-content: flex-end;
}

.header__menuContainer__closebutton > div {
  display: flex;
}

.header__menuContainer__closebutton__text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: auto;
  color: #ffffff;
  margin-right: 12px;
  margin-bottom: 0px;
}

.header__menuContainer__nav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  padding-top: 60px;
}

.header__menuContainer__nav__item {
  margin-bottom: 40px;
  cursor: pointer;
}

.header__menuContainer__nav__item > p {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .header {
    padding: 0px 60px;
  }

  .header__logoContainer__logo {
    width: 80%;
  }

  .header__navMenu button {
    display: none;
  }
  
  .header__navMenu .header__navItems {
    list-style: none;
    display: flex;
    margin-bottom: 0px;
  }

  .header__navItems > li {
    padding-left: 2em;
    font-family: 'Basier Circle';
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #F8F8F8;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .header {
    padding: 50px 100px;
  }
}

.footer {
  margin-top: 40px;
  width: 100%;
  background: #011332;
  color: #FFFFFF;
}

.footer__top {
  padding: 70px 30px;
}

.footer__top__left {
  padding: 0px 0px 30px;
}

.footer__top__left__logoContainer {
  width: auto;
  height: auto;
  margin-bottom: 20px;
}

.footer__top__left__logoContainer > img {
  width: 124px;
  height: auto;
  cursor: pointer;
}

.footer__top__left__companyDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #CCCCCC;
}

.footer__bar__vertical {
  background-color: #011840;
  height: 2px;
  width: 100%;
}

.footer__top__right {
  padding: 30px 0px 0px;
}

.footer__top__right__navMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.footer__top__right__navMenu__navItems {
  list-style: none;
}

.footer__top__right__navMenu__navItems > li {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.footer__top__right__socialIcons__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #E6E6E6;
  margin-bottom: 20px;
}

.footer__top__right__socialIcons__icons {
  width: 55px;
  display: flex;
  justify-content: space-between;
}

.footer__bar__horizontal {
  background-color: #011840;
  width: 100%;
  height: 2px;
}

.footer__bottom__text {
  padding: 50px 0px;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #E6E6E6;
  opacity: 0.6;
}


/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .footer__top {
    padding: 50px 60px 15px;
  }

  .footer__top__left__companyDescription {
    margin-bottom: 2rem;
  }

  .footer__top__right {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  .footer__top__right__navMenu {
    margin-bottom: 0px;
    flex-grow: 2;
  }

  .footer__top__right__navMenu__navItems {
    margin-bottom: 0px;
  }

  .footer__top__right__socialIcons {
    flex-grow: 2;
    display: flex;justify-content: flex-end;
  }

  .footer__bottom {
    padding: 0px 60px;
  }

  .footer__bottom__text {
    padding: 30px 0px;
    font-size: 13px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .footer__top {
    padding: 70px 100px 45px;
    display: flex;
  }

  .footer__top__left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-bottom: 0px;
  }

  .footer__top__left__logoContainer {
    margin-bottom: 0px;
    margin-right: 100px;
    width: 124px;
  }

  .footer__top__left__logoContainer__img {
    width: 100%;
    height: 100%;
  }

  .footer__top__left__companyDescription {
    margin-bottom: 0px;
    max-width: 280px;
    line-height: 24px;
  }

  .footer__bar__vertical {
    display: none;
  }

  .footer__top__right {
    flex-grow: 1;
    padding-top: 0px;
  }

  .footer__top__right__navMenu {
    margin-bottom: 0px;
    flex-grow: 1;
  }

  .footer__top__right__navMenu__navItems > li {
    margin-bottom: 34px;
  }

  .footer__bottom__text {
    padding: 30px 0px;
    font-size: 14px;
  }
}

.section4__box {
  width: 100%;
  height: 472px;
  position: relative;
  overflow: hidden; 
}

.section4__box__image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
}

.section4__box__content {
  width: 100%;
  height: 100%;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* add other icons */
.section4__box__title1,
.section4__box__title2,
.section4__box__title3 {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px;
  border-radius: 1.5px;
  margin-bottom: 20px;
}

.section4__box__title1 {
  background-color: #01424D;
}

.section4__box__title2 {
  background-color: #CE5243;
}

.section4__box__title3 {
  background-color: #BDCD31;
}

.section4__box__title__iconBox {
  margin-right: 10px;
}

.section4__box__title1__text,
.section4__box__title2__text,
.section4__box__title3__text {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}

.section4__box__title1__text {
  color: #B9CC32;
}

.section4__box__title2__text {
  color: #FFFFFF;
}

.section4__box__title3__text {
  color: #01424C;
}

.section4__box__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  text-align: justify;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .section4 {
    display: flex;
    flex-direction: row;
  }

  .section4__box__content {
    padding: 30px 20px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .section4__box {
    height: 620px;
  }

  .section4__box__content {
    padding: 30px 40px;
  }
}
.ab__heroSection {
  padding-top: 80px;
  background: #52C2CC;
}

.ab__heroSection__caption {
  padding: 100px 30px 100px;
}

.ab__heroSection__caption__text1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 45px;
  color: #262424;
}

.ab__heroSection__caption__text2 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: auto;
  color: #111113;
}

.ab__heroSection__images {
  overflow-x: hidden;
}

.ab__heroSection__images1,
.ab__heroSection__images2 {
  width: 100%;
  height: auto;
  margin: 0px;
}

.ab__heroSection__brief {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ab__heroSection__brief__text {
  margin-bottom: 30px;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: auto;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.ab__heroSection__brief > img {
  width: 200px;
}

.accomplishment {
  position: relative;
  overflow-x: hidden;
  height: 700px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accomplishment > img {
  width: auto;
  height: inherit;
  -webkit-transform: translateX(10%);
          transform: translateX(10%);
  position: absolute;
}

.accomplishment__statbox {
  position: absolute;
  padding: 40px;
  width: 90%;
  background: #00539E;
}

.accomplishment__statbox__item {
  margin-bottom: 35px;
}

.accomplishment__statbox__item__heading {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 56px;
  margin-bottom: 5px;
}

.accomplishment__statbox__item__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
}

.history {
  padding: 60px 30px;
  color: #ffffff;
  text-align: center;
}

.history,
.history__heading,
.history__desc {
  background: #00325E;
}

.history__heading {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 47px;
  margin-bottom: 20px;
}

.history__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

.members {
  background-color: #ffffff;
  position: relative;
}

.members__heading {
  padding: 100px 30px 40px;
}

.members__heading__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #262424;
  margin-bottom: 20px;
}

.members__heading__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #262424;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .ab__heroSection__caption {
    padding: 100px 60px 60px;
  }

  .ab__heroSection__caption__text1,
  .ab__heroSection__caption__text2 {
    margin-bottom: 0px;
  }

  .ab__heroSection__images {
    padding: 0px 60px;
  }

  .ab__heroSection__images1 {
    margin-bottom: 30px;
  }

  .ab__heroSection__brief {
    padding: 60px;
  }

  .ab__heroSection__brief__text {
    font-size: 22px;
    line-height: 29px;
  }

  .ab__heroSection__brief > img {
    width: 400px;
  }

  .accomplishment__statbox__item {
    margin-bottom: 50px;
  }

  .history {
    padding: 60px 60px;
  }

  .history__heading {
    font-size: 36px;
    line-height: 53px;
  }

  .history__desc {
    line-height: 32px;
  }

  .members__heading {
    padding: 100px 60px 60px;
  }

  .responsive_grid {
    padding: 0px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .ab__heroSection__caption {
    padding: 120px 100px;
  }

  .ab__heroSection__caption__text1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 16px;
  }

  .ab__heroSection__caption__text2 {
    font-size: 75px;
    line-height: 93px;
    font-weight: 500;
  }

  .ab__heroSection__images {
    padding: 0px 100px;
    display: flex;
    height: auto;
  }

  .ab__heroSection__images__container1 {
    margin-right: 20px
  }

  .ab__heroSection__brief {
    padding: 100px 120px;
  }

  .ab__heroSection__brief__text {
    font-size: 30px;
    line-height: 42px;
  }

  .accomplishment {
    margin-bottom: 80px;
    justify-content: flex-end;
    height: 800px;
  }

  .accomplishment > img {
    -webkit-transform: none;
            transform: none;
    width: 100%;
    height: 100%;
  }

  .accomplishment__statbox {
    max-width: 455px;
    margin-right: 100px;
    padding: 80px 50px;
  }

  .accomplishment__statbox__item {
    margin-bottom: 80px;
  }

  .accomplishment__statbox__item:nth-child(3) {
    margin-bottom: 0px;
  }

  .history {
    padding: 60px 100px;
    margin-top: 80px;
  }

  .members__heading {
    padding: 100px 100px 60px;
  }

  .members__heading__title {
    font-size: 48px;
    line-height: 59px;
    text-transform: capitalize;
    max-width: 12em;
  }

  .members__heading__desc {
    font-size: 20px;
    max-width: 50em;
  }

  .responsive_grid {
    padding: 0px 100px;
  }
}

.testimonialContainer {
  width: 100%;
  height: 780px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.testimonialContainer__content {
  width: 100%;
  padding: 0px 30px;
}

.testimonialContainer__content__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 47px;
  letter-spacing: -0.9px;
  color: #111113;
  text-align: center;
  margin-bottom: 10px;
}

.testimonialContainer__content__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #262424;
  text-align: center;
  margin-bottom: 13px;
}

.testimonialContainer__content__input {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonialContainer__content__input > button {
  height: 50px;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: auto;
  text-align: center;
  color: white;
  background: #CE5243;
  border-radius: 30px;
  border: none;
}

.testimonialContainer__image1 {
  position: absolute;
  z-index: -10;
  top: 0px;
  right: 0px;
}

.testimonialContainer__image2 {
  position: absolute;
  z-index: -11;
  bottom: 0px;
  left: 0px;
}

.testimonialContainer__image1__desktop,
.testimonialContainer__image2__desktop {
  display: none;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .testimonialContainer__content {
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .testimonialContainer__content__title {
    font-size: 36px;
  }

  .testimonialContainer__content__description {
    max-width: 632px;
  }

  .testimonialContainer__content__input {
    width: 400px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) { 
  .testimonialContainer {
    height: 540px;
  }

  .testimonialContainer__content__title {
    font-size: 40px;
    font-weight: 500;
  }

  .testimonialContainer__content__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 22px;
  }

  .testimonialContainer__image1,
  .testimonialContainer__image2 {
    display: none;
  }

  .testimonialContainer__image1__desktop,
  .testimonialContainer__image2__desktop {
    display: block;
    position: absolute;
    height: 100%;
  }

  .testimonialContainer__image1__desktop {
    z-index: -10;
    right: 0px;
  }

  .testimonialContainer__image2__desktop {
    z-index: -11;
    left: 0px;
  }
}

.memberCard {
  margin: 0px 30px 40px;
  border: 2px solid #E6E6E6;
}

.memberCard__person {
  padding: 40px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.memberCard__person > img {
  width: 290px;
  height: auto;
  margin-bottom: 30px;
}

.memberCard__person__caption {
  text-align: center;
}

.memberCard__person__name {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 37px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.memberCard__person__role {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #545454;
  margin-bottom: 8px;
}

.memberCard__person__stay {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #6D6D6D;
}

.memberCard__more {
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}

.memberCard__more > button {
  width: 70%;
  background: none;
  border: none;
}

.memberCard__more > button:focus {
  outline: none;
}

.memberCard__more__button {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.memberCard__more__button > p {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4096DC;
  margin-bottom: 4px;
}

.memberCard__more__button > img {
  width: 24px;
  height: auto;
}

.memberCard__more__text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #262424;
  padding: 20px 20px 0px;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .memberCard {
    margin: 0px 0px 80px;
  }

  .memberCard__person {
    padding: 40px 40px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .memberCard {
    margin: 0px 5px 60px 0px;
  }

  .memberCard__person {
    padding: 40px 80px;
  }
}

.contactInfo {
  padding: 120px 30px 60px;
  background: #F9CFB6;
}

.contactInfo__item:nth-child(1) {
  margin-bottom: 60px;
}

.contactInfo__item > h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #262424;
}

.contactInfo__item > p {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #262424;
  margin: 10px 0px;
}

.contactInfo__item__email, .contactInfo__item__email:visited {
  text-decoration: underline;
}

.googlemapsContainer {
  width: 100%;
  height: 420px;
}

.formbox {
  padding: 45px 30px;
}

.formbox__heading > h1 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: auto;
  color: #000000;
  margin-bottom: 10px;
}

.formbox__heading > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
  margin-bottom: 25px;
}

input {
  background: #F0F0F0;
  border-radius: 2px;
  width: 100%;
  height: 54px;
  margin-bottom: 20px;
  border: none;
  outline-color: #C9C9C9;
  padding: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

input::-webkit-input-placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

input:-ms-input-placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

input::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

textarea {
  background: #F0F0F0;
  border-radius: 2px;
  width: 100%;
  border: none;
  outline-color: #C9C9C9;
  padding: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

.formbox__sendButton {
  width: 100%;
  height: 60px;
  background: #CE5243;
  border-radius: 44px;
  border: none;
  margin-top: 20px;
  color: #fff;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: auto;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) { 
  .contactInfo {
    display: flex;
    justify-content: flex-start;
    padding: 150px 30px 90px;
  }

  .contactInfo__item:nth-child(1) {
    margin-right: 100px;
    margin-bottom: 0px;
  }

  .mapForm {
    display: flex;
    height: 850px;
  }

  .googlemapsContainer {
    width: 50%;
    height: 100%;
  }

  .formbox {
    width: 50%;
  }

  .formbox__sendButton {
    height: 40px;
    font-size: 16px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .mapForm {
    height: 660px;
  }

  .formbox__formgroup {
    display: flex;
  }

  .formbox__formgroup > input:nth-child(1) {
    margin-right: 20px;
  }

  input {
    height: 40px;
    font-size: 16px;
  }

  input::-webkit-input-placeholder {
    font-size: 16px;
  }

  input:-ms-input-placeholder {
    font-size: 16px;
  }

  input::placeholder {
    font-size: 16px;
  }

  .formbox__sendButton {
    height: 40px;
    font-size: 16px;
  }
}

.volunteerImage {
  width: 100%;
  min-height: 400px;
  overflow-x: hidden;
}

.volunteerImage > img {
  width: 100%;
  height: auto;
}

.formbox {
  padding: 45px 30px;
}

.formbox__heading > h1 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: auto;
  color: #000000;
  margin-bottom: 10px;
}

.formbox__heading > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
  margin-bottom: 25px;
}

input {
  background: #F0F0F0;
  border-radius: 2px;
  width: 100%;
  height: 54px;
  margin-bottom: 20px;
  border: none;
  outline-color: #C9C9C9;
  padding: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

input::-webkit-input-placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

input:-ms-input-placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

input::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

textarea {
  background: #F0F0F0;
  border-radius: 2px;
  width: 100%;
  border: none;
  outline-color: #C9C9C9;
  padding: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

.formbox__sendButton {
  width: 100%;
  height: 60px;
  background: #CE5243;
  border-radius: 44px;
  border: none;
  margin-top: 20px;
  color: #fff;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: auto;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .testimonialContainer {
    height: 547px;
  }

  .volunteerImage {
    height: 100px;
    overflow: hidden;
  }
  
  .volunteerImage > img {
    width: 100%;
    height: auto;
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%);
  }

  .formbox {
    width: 100%;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .imgForm {
    padding-top: 100px;
    display: flex;
  }

  .volunteerImage {
    width: 50%;
    height: auto;
  }

  .volunteerImage > img {
    -webkit-transform: none;
            transform: none;
    height: 100%;
  }

  .formbox {
    width: 50%;
    margin: auto 0px;
  }
}

html,body {
  font-family: 'Raleway', sans-serif;  
}

.thankyou-page ._header {
  background: #469EEB;
  padding: 100px 30px;
  text-align: center;
  background: #469EEB url(https://codexcourier.com/images/main_page.jpg) center/cover no-repeat;
}

.thankyou-page ._header .logo {
  max-width: 200px;
  margin: 0 auto 50px;
}

.thankyou-page ._header .logo img {
  width: 100%;
}

.thankyou-page ._header h1 {
  font-size: 65px;
  font-weight: 800;
  color: white;
  margin: 0;
}

.thankyou-page ._body {
  margin: -70px 0 30px;
}

.thankyou-page ._body ._box {
  margin: auto;
  max-width: 80%;
  padding: 50px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
  -moz-box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
  -webkit-box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
}

.thankyou-page ._body ._box h2 {
  font-size: 32px;
  font-weight: 600;
  color: #135e77;
}

.thankyou-page ._footer {
  text-align: center;
  padding: 50px 30px;
}

.thankyou-page ._footer .btn {
  background: #1B6780;
  color: white;
  border: 0;
  font-family: 'Basier Circle';
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  letter-spacing: 0.8px;
  padding: 20px 33px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

._footer > p {
  margin: 0;
}

.ph__heroSection {
  width: 100%;
  height: 550px;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.ph__heroSection > img {
  position: absolute;
  -webkit-transform: translateX(-30%) translateY(-20%);
          transform: translateX(-30%) translateY(-20%);
  z-index: -1;
}

.ph__heroSection__content {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px;
  text-align: center;
}

.ph__heroSection__content > h3 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: auto;
  margin-bottom: 10px;
}

.ph__heroSection__content > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
  color: #E6E6E6;
  margin-bottom: 0px;
}

.projectsList {
  padding: 100px 30px;
  position: relative;
}

.projectList__card {
  height: auto;
  margin-bottom: 30px;
}

.projectList__card > img {
  width: 100%;
}

.projectList__card__content {
  padding: 20px 15px 0px;
  max-width: 360px;
}

.projectList__card__content__date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #585860;
}

.projectList__card__content__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.projectList__card__content__desc {
    font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #262424;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .ph__heroSection {
    height: 396px;
  }

  .ph__heroSection > img {
    -webkit-transform: none;
            transform: none;
    width: auto;
    height: 100%;
  }

  .ph__heroSection__content > h3 {
    font-weight: 500;
    font-size: 36px;
  }
  
  .ph__heroSection__content > p {
    line-height: 27px;
    font-size: 18px;
  }

  .projectsList {
    padding: 60px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .ph__heroSection {
    height: 540px;
  }

  .ph__heroSection > img {
    width: 100%;
    height: auto;
  }

  .ph__heroSection__content > h3 {
    font-size: 45px;
    line-height: 56px;
    text-transform: capitalize;
  }

  .ph__heroSection__content > p {
    line-height: 31px;
    font-size: 22px;
  }

  .projectsList {
    padding: 100px 70px;
  }
}

.pd__herosection {
  width: 100%;
  background-color: #1B6780;
  padding: 180px 30px 30px;
  color: #FFFFFF;
}

.pd__herosection__caption > h1 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: auto;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.pd__herosection__caption > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #E6E6E6;
  margin-bottom: 20px;
}

.pd__herosection > img {
  width: 100%;
  height: auto;
}

.summary {
  padding: 60px 30px 30px;
}

.summary__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #585860;
  margin-bottom: 8px;
}

.summary__text {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.summary__item1 > ul > li {
  list-style-type: none;
}

.summary__item1,
.summary__item2,
.summary__item3,
.summary__item4 {
  margin-bottom: 40px;
}

.deliverable__button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  padding: 8px 12px;
  background: #E6E6E6;
  border-radius: 2px;
}

.deliverable__button > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #585860;
  margin-right: 12px;
  margin-bottom: 0px;
}

.brief {
  padding: 0px 30px;
}

.brief > img {
  width: 100%;
}

.brief__top {
  margin: 30px 0px 50px;
}

.brief__bottom {
  margin-bottom: 50px;
}

.brief__top > h1,
.brief__bottom > h1 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: auto;
  margin-bottom: 10px;
  color: #262424;
}

.brief__top > p,
.brief__bottom > p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #262424;
}

.show__big {
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.show__big__img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.show__big__line {
  width: 200px;
  margin-bottom: 11px;
}

.show__big__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.show__double {
  padding: 0px 30px 50px;
  display: flex;
  flex-direction: column;
}

.show__double > img {
  width: 100%;
  height: auto;
}

.show__double > img:nth-child(1) {
  margin-bottom: 20px;
}

.uniqueness {
  padding: 30px 30px 80px;
}

.uniqueness > p {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: auto;
}


/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .pd__herosection {
    padding: 150px 60px 60px;
  }

  .brief {
    padding: 80px 60px;
  }

  .brief__top {
    margin: 0px 0px 50px;
  }

  .brief__top > p, .brief__bottom > p {
    margin-bottom: 0px;
  }

  .brief__bottom {
    margin-bottom: 0px;
  }

  .show__big {
    padding: 0px 60px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .pd__herosection {
    padding: 200px 100px 100px;
  }

  .pd__herosection__caption > h1 {
    font-weight: 600;
    font-size: 45px;
    line-height: 60px;
    text-transform: capitalize;
    margin-bottom: 35px;
  }

  .pd__herosection__caption > p {
    max-width: 1000px;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 50px;
  }

  .brief {
    padding: 80px 150px;
  }

  .show__big {
    padding: 0px 150px;
  }
}

/* Roboto - regular */
@font-face {
  font-family: 'Roboto';
  src: url(/static/media/roboto-regular-webfont.f36638c2.ttf) format('truetype'), 
       url(/static/media/roboto-regular-webfont.f246b9b1.woff2) format('woff2'), 
       url(/static/media/roboto-regular-webfont.8324cf36.woff) format('woff'); /* Modern Browsers */
}

/* Basier Circle - medium */
@font-face {
  font-family: 'Basier Circle';
  src: url(/static/media/basiercircle-medium-webfont.06f354fc.ttf) format('truetype'), 
       url(/static/media/basiercircle-medium-webfont.ba92e9a5.woff2) format('woff2'), 
       url(/static/media/basiercircle-medium-webfont.8e8754fc.woff) format('woff'); /* Modern Browsers */
}

/* Basier Circle - semibold */
@font-face {
  font-family: 'Basier Circle';
  src: url(/static/media/basiercircle-semibold-webfont.5b580a94.ttf) format('truetype'), 
       url(/static/media/basiercircle-semibold-webfont.808dbc6a.woff2) format('woff2'), 
       url(/static/media/basiercircle-semibold-webfont.77f2a7f7.woff) format('woff'); /* Modern Browsers */
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

