.section4__box {
  width: 100%;
  height: 472px;
  position: relative;
  overflow: hidden; 
}

.section4__box__image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
}

.section4__box__content {
  width: 100%;
  height: 100%;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* add other icons */
.section4__box__title1,
.section4__box__title2,
.section4__box__title3 {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px;
  border-radius: 1.5px;
  margin-bottom: 20px;
}

.section4__box__title1 {
  background-color: #01424D;
}

.section4__box__title2 {
  background-color: #CE5243;
}

.section4__box__title3 {
  background-color: #BDCD31;
}

.section4__box__title__iconBox {
  margin-right: 10px;
}

.section4__box__title1__text,
.section4__box__title2__text,
.section4__box__title3__text {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}

.section4__box__title1__text {
  color: #B9CC32;
}

.section4__box__title2__text {
  color: #FFFFFF;
}

.section4__box__title3__text {
  color: #01424C;
}

.section4__box__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  text-align: justify;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .section4 {
    display: flex;
    flex-direction: row;
  }

  .section4__box__content {
    padding: 30px 20px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .section4__box {
    height: 620px;
  }

  .section4__box__content {
    padding: 30px 40px;
  }
}