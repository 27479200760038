.volunteerImage {
  width: 100%;
  min-height: 400px;
  overflow-x: hidden;
}

.volunteerImage > img {
  width: 100%;
  height: auto;
}

.formbox {
  padding: 45px 30px;
}

.formbox__heading > h1 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: auto;
  color: #000000;
  margin-bottom: 10px;
}

.formbox__heading > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
  margin-bottom: 25px;
}

input {
  background: #F0F0F0;
  border-radius: 2px;
  width: 100%;
  height: 54px;
  margin-bottom: 20px;
  border: none;
  outline-color: #C9C9C9;
  padding: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

input::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

textarea {
  background: #F0F0F0;
  border-radius: 2px;
  width: 100%;
  border: none;
  outline-color: #C9C9C9;
  padding: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
}

.formbox__sendButton {
  width: 100%;
  height: 60px;
  background: #CE5243;
  border-radius: 44px;
  border: none;
  margin-top: 20px;
  color: #fff;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: auto;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .testimonialContainer {
    height: 547px;
  }

  .volunteerImage {
    height: 100px;
    overflow: hidden;
  }
  
  .volunteerImage > img {
    width: 100%;
    height: auto;
    transform: translateY(-30%);
  }

  .formbox {
    width: 100%;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .imgForm {
    padding-top: 100px;
    display: flex;
  }

  .volunteerImage {
    width: 50%;
    height: auto;
  }

  .volunteerImage > img {
    transform: none;
    height: 100%;
  }

  .formbox {
    width: 50%;
    margin: auto 0px;
  }
}
