.memberCard {
  margin: 0px 30px 40px;
  border: 2px solid #E6E6E6;
}

.memberCard__person {
  padding: 40px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.memberCard__person > img {
  width: 290px;
  height: auto;
  margin-bottom: 30px;
}

.memberCard__person__caption {
  text-align: center;
}

.memberCard__person__name {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 37px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.memberCard__person__role {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #545454;
  margin-bottom: 8px;
}

.memberCard__person__stay {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #6D6D6D;
}

.memberCard__more {
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}

.memberCard__more > button {
  width: 70%;
  background: none;
  border: none;
}

.memberCard__more > button:focus {
  outline: none;
}

.memberCard__more__button {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.memberCard__more__button > p {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4096DC;
  margin-bottom: 4px;
}

.memberCard__more__button > img {
  width: 24px;
  height: auto;
}

.memberCard__more__text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #262424;
  padding: 20px 20px 0px;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .memberCard {
    margin: 0px 0px 80px;
  }

  .memberCard__person {
    padding: 40px 40px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .memberCard {
    margin: 0px 5px 60px 0px;
  }

  .memberCard__person {
    padding: 40px 80px;
  }
}
