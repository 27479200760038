.hm__heroSection {
  width: 100%;
  height: 650px;
  overflow: hidden;
  position: relative;
}

.hm__heroSection__image__overlay {
  width: inherit;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: linear-gradient(90deg, #011332 -7.01%, rgba(0, 0, 0, 0) 108.97%);
}

.hm__heroSection__image {
  width: inherit;
  height: 100%;
  position: absolute;
  z-index: -5;
}

.hm__heroSection__image > img {
  height: 140%;
  transform: translateX(-28%);
}

.hm__heroSection__content__container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.hm__heroSection__content__container__items {
  width: 100%;
  padding: 0px 30px;
}

.hm__heroSection__content__container__items > p {
  font-family: Basier Circle;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #FFFFFF;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.section1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: hidden; */
}

.section1__box {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.section1__box > img {
  max-width: 100%;
  height: auto;
  position: relative;
}

.section1__box2 {
  /* padding: 120px 30px; */
  height: 440px;
  background-color: #CE5243;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section1__box2__content {
  padding: 0px 30px;
  overflow-x: hidden;
}

/* change to weight to italic (400) */
.section1__box2__description {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #E6E6E6;
  padding-bottom: 10px;
}

.section1__box2__autor {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #011332;
}

.section1__box2__img1 {
  position: absolute;
  top: 0;
  right: 0;
}

.section1__box2__img2 {
  position: absolute;
  bottom: 0px;
  left: -40px;
}

.section3 {
  width: 100%;
  height: auto;
  background: #00325E;
  padding: 80px 30px;
}

.section3__mainText {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  letter-spacing: 0.6px;
  color: #FFFFFF;
}

.section5 {
  width: 100%;
  height: auto;
  position: relative;
}

.section5__image1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -20;
}

.section5__image2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -24;
}

.section5__heading {
  padding: 80px 30px;
  position: relative;
}

.section5__heading__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #262424;
  margin-bottom: 20px;
}

.section5__heading__subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #585860;
}

.section5__content > img {
  width: 100%;
}

.section5__content__top,
.section5__content__bottom {
  display: flex;
}

.section5__content__box {
  width: 50%;
  display: flex;
}

.section5__content__box__content {
  padding: 35px 30px;
}

.section5__content__box__content__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 56px;
  color: #469EEB;
}

.section5__content__box__content__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #585860;
}

.section6__heading {
  padding: 80px 30px;
}

.section6__heading__top {
  margin-bottom: 80px;
}

.section6__heading__top__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #262424;
  margin-bottom: 20px;
}

.section6__heading__top__subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #262424;
}

.section6__heading__bottom__link {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.section6__heading__bottom__link > a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #469EEB;
  text-decoration: none;
  margin-right: 10px;
}

.section6__content {
  display: grid;
  grid-template-columns: auto;
}

.section6__content__item {
  padding: 60px 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: #00325E;
  color: #FFFFFF;
}

.section6__content__item > img {
  width: auto;
  height: 138px;
}

.section6__content__item__text {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #FFFFFF;
  margin-top: 20px;
}

.section6__content__item:nth-child(2) {
  background: #1B6780;
}

.section6__content__item:nth-child(3) {
  background: #469EEB;
}

.section6__content__item:nth-child(4) {
  background: #F9CFB6;
}

.section6__content__item:nth-child(5) {
  background: #00325E;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .hm__heroSection__content__container__items {
    padding: 0px 60px;
  }

  .hm__heroSection__content__container__items > p {
    max-width: 630px;
  }

  .section1 {
    flex-direction: row;
  }

  .section1__box2 {
    height: auto;
  }

  .section1__box2__img2 {
    max-width: 150px;
    max-height: 150px;
    left: 3px;
  }

  .section1__box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .section3 {
    padding: 80px 60px;
  }

  .section5__heading {
    padding: 80px 60px;
  }

  .section5__content__box__content {
    padding: 35px 60px;
  }

  .section6__heading {
    padding: 80px 60px;
  }

  .section6__content__item {
    padding: 60px 60px;
  }

  .section6__content {
    grid-template-columns: 50% 50%;
  }

  .section6__content__item > img {
    height: 100px;
  }

  .section6__content__item__text {
    font-size: 20px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .hm__heroSection {
    height: 750px;
  }

  .hm__heroSection__image > img {
    transform: none;
    width: 100%;
    height: 100%;
  }

  .hm__heroSection__content__container__items {
    padding: 0px 100px;
  }

  .hm__heroSection__content__container__items > p {
    max-width: 700px;
    margin-bottom: 0px;
    font-size: 45px;
    line-height: 56px;
  }

  .section1__box {
    width: 100%;
    height: auto;
  }

  .section1__box > img {
    width: 100%;
    height: auto;
  }

  .section1__box {
    width: 35%;
  }

  .section1__box2 {
    width: 30%;
  }

  .section1__box2__description {
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 8px;
  }
  
  .section1__box2__autor {
    font-size: 17px;
    font-weight: 400;
  }

  .section3 {
    padding: 150px 100px;
  }
  
  .section3__mainText {
    font-size: 38px;
    padding: 0px;
    font-weight: 500;
    line-height: 49px;
  }

  .section5__heading {
    padding-left: 100px;
    padding-right: 100px;
  }

  .section5__heading__title {
    margin-bottom: 15px;
    max-width: 670px;
    text-transform: capitalize;
  }

  .section5__content > img {
    padding-left: 100px;
    padding-right: 80px;
  }

  .make-element-horiontal {
    width: 100%;
    display: flex;
    padding-left: 100px;
  }

  .section5__content__top, .section5__content__bottom {
    width: 100%;
    display: flex;
  }

  .section5__content__box {
    width: 50%;
    display: block;
  }

  .section5__content__box__content {
    padding: 100px 0px;
  }

  .section5__content__box__content__title {
    font-size: 70px;
    font-weight: 500;
    line-height: 88px;
    margin-top: 0px;
  }

  .section5__content__box__content__description {
    font-size: 17px;
    margin-bottom: 0px;
    max-width: 350px;
  }

  .section6__heading {
    padding-left: 100px;
    padding-right: 100px;
  }

  .section6__heading__top__title {
    margin-bottom: 15px;
    max-width: 670px;
    text-transform: capitalize;
  }

  .section6__heading__top__subtitle {
    color: #585860;
  }

  .section6__content {
    padding-left: 100px;
    padding-right: 100px;
  }

  .section6__content__item__text {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 0px;
  }

  .section6__content__item {
    padding: 90px 60px;
  }

  .section6__content__item > img {
    height: 80px;
  }
}
