@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap');
html,body {
  font-family: 'Raleway', sans-serif;  
}

.thankyou-page ._header {
  background: #469EEB;
  padding: 100px 30px;
  text-align: center;
  background: #469EEB url(https://codexcourier.com/images/main_page.jpg) center/cover no-repeat;
}

.thankyou-page ._header .logo {
  max-width: 200px;
  margin: 0 auto 50px;
}

.thankyou-page ._header .logo img {
  width: 100%;
}

.thankyou-page ._header h1 {
  font-size: 65px;
  font-weight: 800;
  color: white;
  margin: 0;
}

.thankyou-page ._body {
  margin: -70px 0 30px;
}

.thankyou-page ._body ._box {
  margin: auto;
  max-width: 80%;
  padding: 50px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
  -moz-box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
  -webkit-box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
}

.thankyou-page ._body ._box h2 {
  font-size: 32px;
  font-weight: 600;
  color: #135e77;
}

.thankyou-page ._footer {
  text-align: center;
  padding: 50px 30px;
}

.thankyou-page ._footer .btn {
  background: #1B6780;
  color: white;
  border: 0;
  font-family: 'Basier Circle';
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  letter-spacing: 0.8px;
  padding: 20px 33px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

._footer > p {
  margin: 0;
}
