.pd__herosection {
  width: 100%;
  background-color: #1B6780;
  padding: 180px 30px 30px;
  color: #FFFFFF;
}

.pd__herosection__caption > h1 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: auto;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.pd__herosection__caption > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #E6E6E6;
  margin-bottom: 20px;
}

.pd__herosection > img {
  width: 100%;
  height: auto;
}

.summary {
  padding: 60px 30px 30px;
}

.summary__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #585860;
  margin-bottom: 8px;
}

.summary__text {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.summary__item1 > ul > li {
  list-style-type: none;
}

.summary__item1,
.summary__item2,
.summary__item3,
.summary__item4 {
  margin-bottom: 40px;
}

.deliverable__button {
  width: fit-content;
  display: flex;
  align-items: flex-start;
  padding: 8px 12px;
  background: #E6E6E6;
  border-radius: 2px;
}

.deliverable__button > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #585860;
  margin-right: 12px;
  margin-bottom: 0px;
}

.brief {
  padding: 0px 30px;
}

.brief > img {
  width: 100%;
}

.brief__top {
  margin: 30px 0px 50px;
}

.brief__bottom {
  margin-bottom: 50px;
}

.brief__top > h1,
.brief__bottom > h1 {
  width: fit-content;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: auto;
  margin-bottom: 10px;
  color: #262424;
}

.brief__top > p,
.brief__bottom > p {
  width: fit-content;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #262424;
}

.show__big {
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.show__big__img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.show__big__line {
  width: 200px;
  margin-bottom: 11px;
}

.show__big__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.show__double {
  padding: 0px 30px 50px;
  display: flex;
  flex-direction: column;
}

.show__double > img {
  width: 100%;
  height: auto;
}

.show__double > img:nth-child(1) {
  margin-bottom: 20px;
}

.uniqueness {
  padding: 30px 30px 80px;
}

.uniqueness > p {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: auto;
}


/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .pd__herosection {
    padding: 150px 60px 60px;
  }

  .brief {
    padding: 80px 60px;
  }

  .brief__top {
    margin: 0px 0px 50px;
  }

  .brief__top > p, .brief__bottom > p {
    margin-bottom: 0px;
  }

  .brief__bottom {
    margin-bottom: 0px;
  }

  .show__big {
    padding: 0px 60px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .pd__herosection {
    padding: 200px 100px 100px;
  }

  .pd__herosection__caption > h1 {
    font-weight: 600;
    font-size: 45px;
    line-height: 60px;
    text-transform: capitalize;
    margin-bottom: 35px;
  }

  .pd__herosection__caption > p {
    max-width: 1000px;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 50px;
  }

  .brief {
    padding: 80px 150px;
  }

  .show__big {
    padding: 0px 150px;
  }
}
