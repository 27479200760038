.ab__heroSection {
  padding-top: 80px;
  background: #52C2CC;
}

.ab__heroSection__caption {
  padding: 100px 30px 100px;
}

.ab__heroSection__caption__text1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 45px;
  color: #262424;
}

.ab__heroSection__caption__text2 {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: auto;
  color: #111113;
}

.ab__heroSection__images {
  overflow-x: hidden;
}

.ab__heroSection__images1,
.ab__heroSection__images2 {
  width: 100%;
  height: auto;
  margin: 0px;
}

.ab__heroSection__brief {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ab__heroSection__brief__text {
  margin-bottom: 30px;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: auto;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.ab__heroSection__brief > img {
  width: 200px;
}

.accomplishment {
  position: relative;
  overflow-x: hidden;
  height: 700px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accomplishment > img {
  width: auto;
  height: inherit;
  transform: translateX(10%);
  position: absolute;
}

.accomplishment__statbox {
  position: absolute;
  padding: 40px;
  width: 90%;
  background: #00539E;
}

.accomplishment__statbox__item {
  margin-bottom: 35px;
}

.accomplishment__statbox__item__heading {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 56px;
  margin-bottom: 5px;
}

.accomplishment__statbox__item__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
}

.history {
  padding: 60px 30px;
  color: #ffffff;
  text-align: center;
}

.history,
.history__heading,
.history__desc {
  background: #00325E;
}

.history__heading {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 47px;
  margin-bottom: 20px;
}

.history__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

.members {
  background-color: #ffffff;
  position: relative;
}

.members__heading {
  padding: 100px 30px 40px;
}

.members__heading__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #262424;
  margin-bottom: 20px;
}

.members__heading__desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #262424;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .ab__heroSection__caption {
    padding: 100px 60px 60px;
  }

  .ab__heroSection__caption__text1,
  .ab__heroSection__caption__text2 {
    margin-bottom: 0px;
  }

  .ab__heroSection__images {
    padding: 0px 60px;
  }

  .ab__heroSection__images1 {
    margin-bottom: 30px;
  }

  .ab__heroSection__brief {
    padding: 60px;
  }

  .ab__heroSection__brief__text {
    font-size: 22px;
    line-height: 29px;
  }

  .ab__heroSection__brief > img {
    width: 400px;
  }

  .accomplishment__statbox__item {
    margin-bottom: 50px;
  }

  .history {
    padding: 60px 60px;
  }

  .history__heading {
    font-size: 36px;
    line-height: 53px;
  }

  .history__desc {
    line-height: 32px;
  }

  .members__heading {
    padding: 100px 60px 60px;
  }

  .responsive_grid {
    padding: 0px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .ab__heroSection__caption {
    padding: 120px 100px;
  }

  .ab__heroSection__caption__text1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 16px;
  }

  .ab__heroSection__caption__text2 {
    font-size: 75px;
    line-height: 93px;
    font-weight: 500;
  }

  .ab__heroSection__images {
    padding: 0px 100px;
    display: flex;
    height: auto;
  }

  .ab__heroSection__images__container1 {
    margin-right: 20px
  }

  .ab__heroSection__brief {
    padding: 100px 120px;
  }

  .ab__heroSection__brief__text {
    font-size: 30px;
    line-height: 42px;
  }

  .accomplishment {
    margin-bottom: 80px;
    justify-content: flex-end;
    height: 800px;
  }

  .accomplishment > img {
    transform: none;
    width: 100%;
    height: 100%;
  }

  .accomplishment__statbox {
    max-width: 455px;
    margin-right: 100px;
    padding: 80px 50px;
  }

  .accomplishment__statbox__item {
    margin-bottom: 80px;
  }

  .accomplishment__statbox__item:nth-child(3) {
    margin-bottom: 0px;
  }

  .history {
    padding: 60px 100px;
    margin-top: 80px;
  }

  .members__heading {
    padding: 100px 100px 60px;
  }

  .members__heading__title {
    font-size: 48px;
    line-height: 59px;
    text-transform: capitalize;
    max-width: 12em;
  }

  .members__heading__desc {
    font-size: 20px;
    max-width: 50em;
  }

  .responsive_grid {
    padding: 0px 100px;
  }
}
