.header {
  width: 100%;
  height: 80px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  position: absolute;
  z-index: 10;
}

.header__logoContainer {
  width: auto;
  height: auto;
}

.header__logoContainer__logo {
  width: 60%;
  height: auto;
  cursor: pointer;
}

.header__navMenu .header__navItems {
  display: none;
  cursor: pointer;
}

.header__navMenu > button {
  border: none;
  background: none;
}

.header__navMenu__icon {
  cursor: pointer;
}

.header__menuContainer {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #00325E;
  top: 0px;
  left: 0px;
}

.header__menuContainer__closebutton {
  width: 100%;
  height: auto;
  padding: 30px;
  display: flex;
  justify-content: flex-end;
}

.header__menuContainer__closebutton > div {
  display: flex;
}

.header__menuContainer__closebutton__text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: auto;
  color: #ffffff;
  margin-right: 12px;
  margin-bottom: 0px;
}

.header__menuContainer__nav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  padding-top: 60px;
}

.header__menuContainer__nav__item {
  margin-bottom: 40px;
  cursor: pointer;
}

.header__menuContainer__nav__item > p {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .header {
    padding: 0px 60px;
  }

  .header__logoContainer__logo {
    width: 80%;
  }

  .header__navMenu button {
    display: none;
  }
  
  .header__navMenu .header__navItems {
    list-style: none;
    display: flex;
    margin-bottom: 0px;
  }

  .header__navItems > li {
    padding-left: 2em;
    font-family: 'Basier Circle';
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #F8F8F8;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) {
  .header {
    padding: 50px 100px;
  }
}
