.testimonialContainer {
  width: 100%;
  height: 780px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.testimonialContainer__content {
  width: 100%;
  padding: 0px 30px;
}

.testimonialContainer__content__title {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 47px;
  letter-spacing: -0.9px;
  color: #111113;
  text-align: center;
  margin-bottom: 10px;
}

.testimonialContainer__content__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #262424;
  text-align: center;
  margin-bottom: 13px;
}

.testimonialContainer__content__input {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonialContainer__content__input > button {
  height: 50px;
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: auto;
  text-align: center;
  color: white;
  background: #CE5243;
  border-radius: 30px;
  border: none;
}

.testimonialContainer__image1 {
  position: absolute;
  z-index: -10;
  top: 0px;
  right: 0px;
}

.testimonialContainer__image2 {
  position: absolute;
  z-index: -11;
  bottom: 0px;
  left: 0px;
}

.testimonialContainer__image1__desktop,
.testimonialContainer__image2__desktop {
  display: none;
}

/* Media queries responsive :: tablets */
/* ------------------------------------------------- */
@media (min-width: 769px) {
  .testimonialContainer__content {
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .testimonialContainer__content__title {
    font-size: 36px;
  }

  .testimonialContainer__content__description {
    max-width: 632px;
  }

  .testimonialContainer__content__input {
    width: 400px;
  }
}

/* Media queries responsive :: desktop */
/* ------------------------------------------------- */
@media (min-width: 1025px) { 
  .testimonialContainer {
    height: 540px;
  }

  .testimonialContainer__content__title {
    font-size: 40px;
    font-weight: 500;
  }

  .testimonialContainer__content__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 22px;
  }

  .testimonialContainer__image1,
  .testimonialContainer__image2 {
    display: none;
  }

  .testimonialContainer__image1__desktop,
  .testimonialContainer__image2__desktop {
    display: block;
    position: absolute;
    height: 100%;
  }

  .testimonialContainer__image1__desktop {
    z-index: -10;
    right: 0px;
  }

  .testimonialContainer__image2__desktop {
    z-index: -11;
    left: 0px;
  }
}
